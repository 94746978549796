.about-card {
    background: rgb(var(--bg-2));
    border: 1px solid rgb(var(--bg-3));
    transition: var(--transition);
    height: 100%;
    text-align: center;
}
.about-card:hover {
    cursor: pointer;
    background: rgb(var(--primary-1));
}
.about-card .icon {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(var(--primary-1));
    color: rgb(var(--n700));
    transition: var(--transition);
    font-size: 29px;
    margin: 0 auto;
    margin-bottom: 16px;
}
.about-card:hover .icon {
    background: rgb(var(--n700));
    color: rgb(var(--primary-1));
}
.about-card:hover .contac-title,
 .about-card:hover .description 
  {
    color: rgb(var(--n700));
}
.counter-section .counter-card {
    background: rgb(var(--bg-2));
    transition: var(--transition);
    padding: 50px;
    cursor: pointer;
    /* height: 100%; */
}
.counter-section .counter-card:hover {
    background: rgb(var(--primary-1));
}
.counter-section .counter-card:hover .base, .counter-section .counter-card:hover .descrip,
.counter-section .counter-card:hover .counter-item p {
    color: rgb(var(--n700));
}
.counter-section .counter-card:hover .text {
    color: rgb(var(--n700));
}
.counter-section .counter-card .text {
    font-size: 68px;
    padding: 0px 5px;
    /* FONT-SIZE: 68PX; */
    color: rgb(var(--primary-1));
}
.descrip {
    font-weight: 500 !important;
    font-size: 20px;
    margin: -12px;
    color: #3ae57a;
}
.counter-item p {
    padding-top: 13px;
    text-align: center;
}

.counter-item-head  {
    text-align: center;
    padding: 0px 0px 10px 0px;
}
.counter-item-head h6 {
    font-size: 32px;
    font-weight: 100;
}
.counter-item-head p {
    font-size: 18px;
    font-weight: 300;
    padding: 20px;
}

@media(max-width:991px){
    .odometer-item span {
        font-size: 35px;
      
    }
    .counter-section .counter-card .text {
        font-size: 42px;
    }
}

@media(max-width:767px){
    .counter-section .counter-card {
        padding: 26px;
    }
    .counter-item-head h6 {
        font-size: 26px;
    }
   .abputs-nt {
        padding: 65px 0px 40px 0px;
    }
    .about-card {
        height: auto;
    }
}

@media(max-width:425px){
    .counter-item-head h6 {
        font-size: 22px;
    }
    .counter-item-head p {
        font-size: 16px;
        font-weight: 300;
        padding: 10px;
    }
    .counter-section .counter-card {
        padding: 15px;
    }
    .odometer-item span {
        font-size: 23px;
    }
    .counter-item p {
        padding-top: 7px;
        text-align: center;
    }
}