.border-dashed {
  border-top: 1px dashed rgba(var(--primary-1), 0.2);
}
.form-control {
  display: block;
  width: 100%;
  padding: 13px 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  border-radius: 32px;
  background: rgb(var(--bg-3));
  border: none;
}
.form-control::placeholder {
  color: #fff !important;
}
.form-control:focus {
  background: rgb(var(--bg-3));
  border: none;
  box-shadow: none;
  color: #fff;
}
.office-address-section .office-address-card {
  background: rgb(var(--bg-2));
  border: 1px solid rgb(var(--bg-3));
  transition: var(--transition);
}
.office-address-section .office-address-card:hover {
  cursor: pointer;
  background: rgb(var(--primary-1));
}
.office-address-section .office-address-card:hover .contac-title,
.office-address-section .office-address-card:hover .description,
.office-address-section .office-address-card:hover .link-btn {
  color: rgb(var(--n700));
}
.office-address-section .office-address-card:hover .icon {
  background: rgb(var(--n700));
  color: rgb(var(--primary-1));
}
.office-address-section .office-address-card .icon {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--primary-1));
  color: rgb(var(--n700));
  transition: var(--transition);
  font-size: 29px;
}
.link-btn {
  color: rgb(var(--primary-1));
  border-bottom: 1px solid rgb(var(--primary-1));
  transition: var(--transition);
}
.contac-title {
  font-size: 30px;
}

/* /////////////////expert hire ///////////////// */
.hire-experts {
  margin-bottom: 20px;
}
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
  width: 100%;
  background: #3b3b3c;
  border-radius: 32px;
  padding: 0px 17px;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  line-height: 51px;
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select-trigger {
  position: relative;
  display: block;
  width: 100%;
  padding: 0px 17px 0px 0px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  line-height: 51px;
  background: #3b3b3c;
  border-radius: 32px;
  cursor: pointer;
}
.custom-select-trigger:after {
  position: absolute;
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  top: 50%;
  right: 0px;
  margin-top: -3px;
  border-bottom: 1px solid #fcb440;
  border-right: 1px solid #fcb440;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.4s ease-in-out;
  transform-origin: 50% 0;
}

.custom-select.opened .custom-select-trigger:after {
  margin-top: 3px;
  transform: rotate(-135deg) translateY(-50%);
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 100%;
  margin: 15px 0;
  /* border: 1px solid #b5b5b5; */
  border-radius: 4px;
  box-sizing: border-box;
  /* box-shadow: 0 2px 1px rgba(0, 0, 0, 0.07); */
  background: #ffb430;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  z-index: 9;
}

.custom-select.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}

.custom-option {
  position: relative;
  display: block;
  padding: 0 22px;
  /* border-bottom: 1px solid #b5b5b5; */
  font-size: 16px;
  font-weight: 400;
  color: #000;
  line-height: 35px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.custom-option:first-of-type {
  border-radius: 4px 4px 0 0;
}

.custom-option:last-of-type {
  border-bottom: 0;
  border-radius: 0 0 4px 4px;
}

.custom-option:hover,
.custom-option.selection {
  background: #ffb430;
}
.expert-siup {
  margin-bottom: 20px;
}

.tags-input-container {
  flex-wrap: wrap;
}
input.form-control-1 {
  width: 100%;
  background: #3b3b3c;
  border: none;
  border-radius: 32px;
  padding: 8px;
  font-size: 15px;
  padding: 10px 20px;
  color: #fff;
}
.form-control-1::placeholder {
  color: #fff;
  opacity: 1; 
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.tags-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.tag-item {
  background-color: transparent;
  color: white;
  border: 1px solid #ffb43059;
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  margin: 4px 4px;
}

.remove-tag {
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
  font-size: 30px;
  color: #ffb430;
}

/* /////////////////////// request css ////////////////// */
.request-heading h5 {
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 6px;
}
.contactrequest-12 {
  background: rgb(var(--bg-2));
  padding: 30px 10px;
}
.custom-file-input {
  position: relative;
  overflow: hidden;
}
.custom-file-input::file-selector-button {
  background: rgb(var(--bg-3));
  color: white;
  border: 1px solid #585858;
  border-radius: 30px;
  cursor: pointer;
}
.custom-file-input::file-selector-button:hover {
  background: rgb(var(--bg-3)) !important;
  color: #000 !important;
}
label.Request_field {
  padding-bottom: 10px;
  font-size: 15px;
}
.form-select {
  display: block;
  width: 100%;
  padding: 13px 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  border-radius: 32px;
  background: rgb(var(--bg-3));
  border: none;
}
.form-select::placeholder {
  color: #fff !important;
}
.form-select:focus {
  background: rgb(var(--bg-3));
  border: none;
  box-shadow: none;
  color: #fff;
}
.rqfrom-us-new {
  padding-bottom: 33px;
}
button.main-btn-resuest {
  background: #ffb430;
  border: navajowhite;
  padding: 10px;
  border-radius: 30px;
  margin: 0 auto;
  display: block;
  width: 200px;
}
.form-t11.mb-4 p {
  color: #ffb430;
  font-size: 18px;
  font-weight: 600;
}
.list-of-tags {
  background: #ffb340;
  color: #000;
  border-radius: 4px;
  margin: 15px 0px;
  font-weight: 400;
  width: 100%;
}
.list-of-search-tag {
  font-size: 16px;
  font-weight: 400;
  padding: 0px 18px;
  line-height: 23px;
  cursor: pointer;
}
.swiper-scrollbar-drag {
  background: transparent !important;
}


@media (max-width: 1024px) {
  .office-address-section .office-address-card {
    display: block !important;
  }
  .contac-title {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .contactrequest-12 {
    margin-bottom: 32px;
  }
  .sign-up-form {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .office-address-section .office-address-card {
    margin-bottom: 10px;
  }
  .request-heading h5 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 6px;
  }
}

@media (max-width: 575px) {
  .contac-title {
    font-size: 23px;
    font-weight: 500 !important;
  }
  .contact-us-content-wrapper {
    padding: 50px 0px 30px 0px;
  }
  .office-address-section .office-address-card .icon {
    width: 45px;
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    font-size: 26px;
    margin-bottom: 10px;
  }
  .request-heading.mb-5 {
    text-align: center;
    padding-top: 24px;
  }
  .request-heading h5 {
    font-size: 26px;
  }
  .req-form-image.aos-init img.w-100 {
    width: 50% !important;
    margin: 0 auto;
    display: block;
  }
}

@media (max-width: 375px) {
  .request-heading h5 {
    font-size: 24px;
  }
}
