.texture-bg-2 {
  background: url("../../../assets/images/texture-bg-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.featured-section .features-card {
  border-right: 1px dashed rgba(var(--primary-1), 0.5);
}
.swiper-btns .button-prev,
.swiper-btns .button-next {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(var(--primary-1));
  transition: var(--transition);
  color: rgb(var(--primary-1));
  cursor: pointer;
  font-size: 24px;
  position: relative;
}
.lifecycle-text {
  font-size: 28px;
  line-height: 120%;
  font-weight: 400;
}
.features-card.lefecycle.text-center {
  display: block;
  justify-content: center;
  text-align: center;
  padding: 0px 21px 0px 0px;
}
p.lifecycle-paragraph {
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
  margin: 0;
}
.card-icon i {
  font-size: 45px;
}
.lyfcle-bt {
  float: inline-end;
}
.features-card.lefecycle.text-center.last-bor-none {
  border-right: none;
}
p.fs-xl.testimon-pra {
  font-size: 20px;
}
@media (max-width: 991px) {
  .lyfcle-bt {
    margin-top: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    float: inline-start;
    margin-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .featured-section .features-card {
    border-right: 0px dashed rgba(var(--primary-1), 0.5);
    border-left: 0px dashed rgba(var(--primary-1), 0.5);
  }
}

@media (max-width: 425px) {
  .lifecycle-text {
    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .swiper-btns .button-prev,
  .swiper-btns .button-next {
    width: 37px;
    height: 37px;
  }
}
@media (max-width: 320px) {
  .card-title {
    font-size: 20px;
  }
}
/* ////////////////////// Why Choose Us css /////////////// */
.subheading-border {
  color: rgb(var(--primary-1));
  border: 1px solid rgb(var(--primary-1));
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  line-height: 0;
  font-size: 20px;
  font-weight: 400 !important;
}

.elevate-your-experience .icon-area {
  max-width: 60px;
  max-height: 60px;
}

.elevate-your-experience .icon-area i {
  font-size: 41px;
}

.elevate-your-experience .elevate-experience-banner .banner-1 {
  max-width: 360px;
  max-height: 485px;
  width: 100%;
  height: 100%;
  transform: rotate(-12deg);
  position: relative;
  z-index: 1;
}
.elevate-your-experience .elevate-experience-banner .banner-shape-1 {
  max-height: 180px;
  max-width: 280px;
  width: 100%;
  height: 100%;
  transform: translateX(136px) translateY(-54px);
}
.elevate-your-experience .elevate-experience-banner .banner-2 {
  max-width: 270px;
  max-height: 355px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(85px);
  z-index: 1;
}
.elevate-your-experience .elevate-experience-banner .banner-shape-2 {
  max-width: 80px;
  max-height: 130px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: -1;
}
.elevate-your-experience .elevate-experience-banner .year-of-experience {
  position: absolute;
  max-width: 248px;
  width: 100%;
  right: 0;
  top: 66%;
  z-index: 1;
  padding: 23px;
  /* transform: translateX(100px); */
}
.odometer-item span {
  font-size: 54px;
  font-weight: 600;
}

@media (max-width: 991px) {
  /* .elevate-your-experience .elevate-experience-banner .banner-1 {
        transform: rotate(1deg);
    } */
  .elevate-your-experience .elevate-experience-banner .banner-2 {
    max-width: 343px;
    transform: translateX(0px);
  }
  .elevate-your-experience .elevate-experience-banner .banner-2 {
    right: unset;
    left: 50%;
    transform: unset;
  }
  .elevate-your-experience .elevate-experience-banner .year-of-experience {
    max-width: 220px;
    right: unset;
    left: 50%;
    top: 70%;
    transform: unset;
  }
  .elevate-your-experience .elevate-experience-banner .banner-shape-2 {
    max-height: 60px;
    position: absolute;
    right: -45px;
  }
}

@media (max-width: 767px) {
  .elevate-your-experience .elevate-experience-banner .banner-1 {
    max-width: 280px;
    max-height: 385px;
  }
  .elevate-your-experience .elevate-experience-banner .banner-2 {
    max-width: 220px;
    max-height: 355px;
  }
  .elevate-your-experience .elevate-experience-banner .banner-shape-2 {
    right: 0px;
  }
  .elevate-your-experience .elevate-experience-banner .year-of-experience {
    top: 63%;
  }
  .elevate-your-experience .elevate-experience-banner .banner-shape-1 {
    max-height: 120px;
    max-width: 180px;
  }
}
/* //////////////////////// project css///////////////////////////    */
.episode-card {
  padding: 20px;
  min-height: 402px;
  height: 100%;
}
.episode-time i {
  font-size: 20px;
}
i.play-icon {
  position: relative;
  left: 3px;
}
.icon-btn .text {
  color: rgb(var(--primary-1));
  border-bottom: 1px solid rgb(var(--primary-1));
  transition: var(--transition);
}
.card-title {
  font-size: 31px;
  font-weight: 400;
}
.record-img-animation.height-46 {
  height: 46px;
}
.record-img-animation {
  width: 100%;
}
.record-img {
  max-width: 300px;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 425px) {
  .card-title {
    font-size: 22px;
  }
}

/* //////////////////////////// Contactnews /////////////////// */

section.newsletter-section-4 {
  position: relative;
}
.newsletter-section-4::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background: url("../../../assets//images/texture-bg.png"),
    rgb(var(--secondary-1));
  z-index: -1;
}

.live-chat-item {
  max-width: 241px;
  width: 100%;
  color: #fff;
  padding: 23px;
}
.newsletter-section-4 .news-4-banner .img-area {
  max-width: 280px;
  max-height: 270px;
  width: 100%;
}
.newsletter-section-4 .news-4-banner .news-width {
  width: 50%;
}

@media (max-width: 991px) {
  .newsletter-section-4::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .news-4-banner.mb-4 {
    margin-top: 43px;
  }
}

@media (max-width: 575px) {
  /* .newsletter-section-4 .news-4-banner .news-width {
        width: 100%;
    }
 */

  .newsletter-section-4 .news-4-banner .news-width {
    width: 100%;
  }
  h2.hero-title {
    font-size: 30px;
  }
}

/* /////////////////// expert profile ////////////  */

.host-card .host-profile .img-area {
  transition: var(--transition);
}
.host-info {
  padding: 27px 0px 0px 0px;
  text-align: center;
}
.host-info h4 {
  font-size: 20px;
  font-weight: 400 !important;
  margin-bottom: 0px !important;
  color: #fcb440;
}
.host-info span {
  font-size: 16px;
  font-weight: 400;
}
ul li {
  list-style: none;
}

/* ////////////////////client css /////////////////////// */
.testimonial-section-04 .ts-thumb img {
  border-radius: inherit;
}
.testimonial-section-04 .ts-thumb {
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0.7);
  cursor: pointer;
  transition: var(--transition);
}
.testimonial-section-04 .testimonial-quote .quote-icon {
  max-width: 80px;
  max-height: 80px;
  width: 100%;
}
.testimonial-section-04 .swiper-slide-thumb-active .ts-thumb {
  width: 140px;
  height: 140px;
  transform: scale(1);
  border: 2px solid rgb(var(--n0));
}
.clients-sp {
  width: 50%;
  margin: 0 auto;
  padding-top: 50px;
}

@media (max-width: 991px) {
  .testimonial-section-04 .ts-thumb {
    max-width: 100px;
    max-height: 100px;
  }
}

@media (max-width: 425px) {
  .clients-sp {
    width: 70% !important;
    margin: 0 auto;
    padding-top: 30px !important;
  }
  .testimonial-section-04 .ts-thumb {
    max-width: 90px;
    max-height: 90px;
  }
  .testimonial-section-04 .testimonial-quote .quote-icon {
    max-width: 45px;
    max-height: 45px;
  }
}
