.details button.btn.btn-primary {
    background: transparent;
    color: #fff;
    border-radius: 23px;
    border: 1px solid #fff;
    width: 42px;
    float: right;
    height: 42px;
  }
  .portfolio-1 {
    margin-bottom: 22px;
  }
  .frame img {
    width: 100%;
    height: 100%;
  }
.fram-details h1 {
  padding: 20px 0px 0px 0px;
  color: #fff;
  font-size: 23px;
  margin-bottom: 0px;
  font-weight: 400;
}
.fram-details p {
  color: #fff;
  font-size: 17px;
  padding-bottom: 15px;
}
a.all-read-more {
  color: #ffb430;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
}
  .allproject-heding {
    text-align: center;
}
  button.btn65,
  button.btn66 {
    border-radius: 40px 40px 40px 20px;
    cursor: pointer;
    padding: 14px 16px;
  }
.allproject-heading{
  text-align: center;
}
  .allproject-heding h5 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 0px;
}
.allproject-heding p {
  font-size: 18px;
  margin-bottom: 40px;
}
  .descriptions-p h4 {
    font-size: 18px;
}
h3.pro {
  color: #fcb440;
  padding: 25px 0px;
  margin-bottom: 0px;
  font-size: 35px;
}
  button.btn65 {
    background: #FFB430;
    border: none;
    color: #000;
    margin-left: 10px;
  }
  button.btn66 {
    background: #09e57f;
    border: none;
    color: #000;
    margin-left: 7px;
  }
  .bg-salmon {
    background-color: salmon;
    color: white;
  }
 .new-title-model{
  position: relative;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 22px;
  padding: 10px 20px;
 }
  .item-image img.portfolio {
    width: 100%;
    cursor: pointer;
    /* border-radius: 12px; */
  }
  .item-image {
    border: 1px dashed #ccc;
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
}
  @media (max-width: 435px) {
    button.btn65,
    button.btn66 {
      padding: 16px;
      font-size: 13px;
    }
  }
  @media (max-width: 359px) {
    button.btn66 {
      padding: 14px;
    }
  }
  @media (max-width: 358px) {
    .frame .details {
      padding: 67px 20px 0px;
    }
    .details p {
      font-size: 14px !important;
    }
  }
  @media (max-width: 347px) {
    button.btn65,
    button.btn66 {
      padding: 12px;
      font-size: 13px;
    }
  }

  @media (max-width: 322px) {
    .frame .details {
      padding: 42px 20px 0px;
    }
  }
  @media (max-width: 327px) {
    button.btn65,
    button.btn66 {
      padding: 9px;
      font-size: 12px;
    }
  }

  /* /////////////////////portfolio details/////////////////////// */
.caseStudyDetail h2 {
    font-size: 32px;
    padding-bottom: 15px;
}
.caseStudyDetail .tagList {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    gap: 15px;
}
.caseStudyDetail .tagList li {
  border: 1px solid #ffb43047;
  font-size: 16px;
  padding: 15px 21px;
  background-color: transparent;
  color: #ffb430;
  border-radius: 5px;
  line-height: 1.25;
  display: flex;
  align-items: center;
}
ul.platform {
    display: flex;
    padding-left: 0px;
    /* padding: 10px 13px; */
}
 .bannerSec_cntBox .platform li:not(:last-child):after {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 1px;
    height: 20px;
    background-color: rgba(57,57,57,.2);
    content: "";
    transform: translateY(-50%);
}

.icon-web {
    color: #fff;
    font-size: 19px;
    margin: 0px 10px 0px 0px;
}
.icon-ios {
    font-size: 19px;
    margin: 0px 10px 0px 0px;
}
.icon-android{
    font-size: 19px;
    margin: 0px 10px 0px 0px;
}
.black span {
    font-size: 19px;
    margin: 0px 10px 0px 0px;
}

li.green {
    margin: 2px 28px 0px 10px;
}
.project-new-img img {
  width: 100%;
  border: 2px solid #ffb430;
  border-radius: 5px;
}

.aboutSec_rightBox {
  padding: 0px 0px 0px 64px;
}
.aboutSec .diversityList>li {
    padding-left: 13px;
}
.diversityList>li {
  padding-left: 25px;
  position: relative;
  margin-bottom: 40px;
}
.diversityList>li:before {
  width: 2px;
  height: 61px;
    position: absolute;
    top: 3px;
    left: 0;
    content: "";
    background: linear-gradient(135deg,#FFB430 50%,#09e57f 50%);
}
span.diversityList_title {
  font-size: 28px;
  font-weight: 400;
}
.diversityList>li p {
  font-size: 20px;
  padding: 6px 0px;
}
.leveragedSec_cnt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    background-color: #2c2c2e;
    color: #fff;
}
i.html {
    font-size: 40px;
    color: #ff7600;
}
i.css {
    color: #556bd3;
    font-size: 40px;
}
i.react {
    color: #0dcaf0;
    font-size: 40px;
}
i.bootstrap {
    color: rgb(165 33 181);
    font-size:40px;
}

i.node {
    color: green;
    font-size: 40px;  
}
i.Mongodb {
    color: #198754;
    font-size: 40px; 
}
.project-laguage {
    margin-bottom: 38px;
}
a.dt-link {
  /* margin-top: 10px; */
  /* padding: 20px 0px; */
  color: #3ce981;
}
.leveragedSec_cnt h5 {
  text-align: center;
}

@media(max-width:991px){
  .aboutSec_rightBox {
    padding: 29px 0px 0px 0px;
}
}
@media(max-width:575px){
  .allproject-heding h5 {
    font-size: 24px;
    margin-bottom: 10px;
}
.allproject-heding p {
  font-size: 18px;
  margin-bottom: 20px;
}
.caseStudyDetail h2 {
  font-size: 24px;
  padding-bottom: 3px;
}
span.diversityList_title {
  font-size: 21px;
  font-weight: 400;
}
.diversityList>li p {
  font-size: 16px;
  padding: 0px 0px;
}
}


@media(max-width:425px){
  .allproject-heding p {
    font-size: 17px;
    margin-bottom: 20px;
}
.allproject-heding h5 {
  font-size: 22px;
  
}
.fram-details h1 {
  font-size: 23px;
  font-weight: 400;
}
}

@media(max-width:375px){
  .caseStudyDetail h2 {
    font-size: 21px;
    padding-bottom: 3px;
}
}