img.h-logo {
  width: 70px;
}
.header {
  width: 100%;
  position: fixed;
  padding: 0px;
  background: #000 !important;
  z-index: 9;
}
.dropdown-toggle::after{
  display: none;
}
.h-link{
  color: #fff;
  cursor: pointer;
  text-decoration: none; 
  padding: 0px 12px;
}
svg.plus {
  font-size: 12px;
}

.header-btn {
  display: inline-flex;
  padding: 8px  20px 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  transition: var(--transition);
  background: rgb(var(--primary-1));
  color: rgb(var(--n900));
  font-weight: 600;
  position: relative;
}
.hire-btn {
  border: 1px solid #fcb440;
  padding: 8px  20px 8px 20px !important;
  border-radius: 32px;
  color: #fcb440;
  font-weight: 600;
  font-size: 16px;
  margin-right: 16px;
}
.h-link.active{
  color: #ffb430;
  font-weight: 500;
}
@media(max-width:1006px){
  .h-link {
    padding: 0px 8px;
}
}

@media(max-width:991px){
  .h-link {
    padding: 11px 12px;
}
.navbar-nav {
  padding: 11px 0px;
}
a.header-btn {
  margin-bottom: 31px;
}
.hire-btn {
  width: 153px;
}
button.navbar-toggler.collapsed {
  border: none;
  box-shadow:none !important;

}
.navbar-toggler:focus {
  box-shadow: none !important;
}
}