.product-head.bgc-2 {
    padding: 30px 25px 30px 25px;
    width: 100%;
    margin-top: 10px;
    min-height: 456px;
}
.products-icons {
    background: #FFB340;
    width: 40px;
    height: 40px;
   
}
.product-head h5 {
    font-size: 28px;
    height: 70px;
}
.product-head p {
    font-size: 17px;
    margin-bottom: 10px;
    height: 195px;
}
.product-head a {
    background-color: transparent;
    border-radius: 27px;
    float: right;
    padding: 6px 20px;
    color: #fcb440;
    border: 1px solid #FFB430;
}
.products-icons {
    font-size: 34px;
    background: #FFB340;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    line-height: 46px;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 30px;
}

@media(max-width:1199px){
    .product-head h5 {
        font-size: 28px;
        height: 100px;
    }
    .product-head p {
        font-size: 17px;
        margin-bottom: 10px;
        height: auto;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        display: -webkit-box;
        overflow: hidden;
    }
}

@media(max-width:1024px){
    .product-head h5 {
        font-size: 24px;
        height: 58px;
    }
}

@media(max-width:767px){
    .product-head.bgc-2 {
        padding: 30px 25px 30px 25px;
        width: 100%;
        margin-top: 10px;
        min-height: 350px;
    }
    .product-head h5 {
        font-size: 24px;
        height: auto;
    }
}


@media(max-width:575px){
    .product-head.bgc-2 {
        padding: 30px 25px 60px 25px;
        width: 100%;
        height: auto;
        margin-top: 10px;
        min-height: auto;
    }
}

@media(max-width:425px){
    .product-head h5 {
        font-size: 21px;
        height: auto;
    }
    .products-icons {
        font-size: 24px;
        width: 46px;
        height: 46px;
        margin-bottom: 13px;
    }
}