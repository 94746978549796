.tag-btn-5 {
    background: #FFB430;
    border-radius: 30px;
    color: #000;
    padding: 11px 18px;
}
.recent-article-card {
    padding: 20px;
    height: 100%;
    width: 100%;
    min-height: 730px;
}
ul.pagination {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
li.page-item {
    padding: 5px 10px;
}
a.page-link {
    color: #FFB430;
    border: 1px solid #FFB430;
    background-color: transparent;
}
.active>.page-link, .page-link.active {
    background: #FFB430 !important;
    color: #000;
    border: none;
}
img.blog-img.w-100.rounded {
    width: 100%;
    height: 291px;
    object-fit: cover;
}
.new-comment-from {
    background-color: #343a4000;
    padding: 21px;
    border: 22px solid #3b3b3c;
    border-radius: 6px;
}
.blog-card-item.d-flex.align-items-center.gap-4 {
    display: block !important;
}
.blog-card-item.d-flex.align-items-center.gap-4 .img-area {
    margin-bottom: 18px;
}
button.like-btn {
    background: transparent;
    border: navajowhite;
    color: #ffb430;
}
button.comment-btn {
    background: transparent;
    color: #ffb430;
    border: navajowhite;
}

@media(max-width:991px){
    .recent-article-card {
        padding: 20px;
        height: 100%;
        width: 100%;
        min-height: auto;
    }
}

@media(max-width:767px){
    .recent-article-card {
        padding: 20px;
        height: auto;
        width: 100%;
        min-height: auto;
        margin-bottom: 10px;
    }
}

@media(max-width:425px){
    li.page-item {
        padding: 5px 4px;
    }
}