.texture-bg {
    background: url("../../../assets/images/texture-bg.png"), rgb(var(--bg-2));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
h2.display-two {
  font-size: 70px;
  font-weight: 600 !important;
  margin-bottom: 0px;
}
p.hero-description {
    padding: 30px 0px 40px 0px;
    font-size: 21px;
    font-weight: 200;
    line-height: 32px;
    margin-bottom: 0px;
}
ul{
    padding-left: 0px;
}
li.brand-icon {
    width: 48px;
    height: 48px;
    list-style: none;
}
.listen-to-btns {
    padding: 40px 0px 0px 0px;
}

.hero-section .hero-banner .banner-left-items {
    margin-top: -140px;
}
.hero-section .hero-banner .banner-item-1 {
    border-radius: 0 0 200px 200px;
}
.hero-section .hero-banner .img-area {
    max-width: 220px;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
}
.hero-section .hero-banner .banner-item-2, .hero-section .hero-banner .banner-item-3 {
    border-radius: 200px;
}
.hero-section .hero-banner .banner-item-4 {
    border-radius: 200px 200px 0 0;
}
.hero-section {
  position: relative; 
}
.hero-section {
  position: relative;
  overflow: hidden; 
}

@keyframes smallScroll {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(50px, 0);
  }
  50% {
    transform: translate(30px,10);
  }
  75% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.hero-section .hero-banner {
  position: absolute;
  animation: smallScroll 15s ease-in-out infinite alternate-reverse;
  -webkit-animation: smallScroll 15s ease-in-out infinite alternate-reverse;
}

.d-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.gap-6 {
    gap: 1.5rem !important;
}
img.w-100.line {
    margin-top: 76px;
}


@media(max-width:767px){
    h2.display-two{
        font-size: 52px;
        text-align: center;

    }
    .hero-section .hero-banner .banner-left-items {
        margin-top: 0;
    }
    .hero-section .hero-banner {
        position: relative;

    }
    p.hero-description{
        text-align: center;
    }
    .hero-btn-area.d-flex {
        justify-content: center;
    }
    .listen-to-btns {
        padding: 40px 0px 40px 0px;
        text-align: center;
        justify-content: center;
        display: grid;
    }
    .hero-section .hero-banner .img-area {
        max-width: 180px;
        max-height: 300px;
    }
}
@media (max-width: 575px) {
    h2.display-two {
        font-size: 48px;
    }
    li.brand-icon {
        width: 36px;
        height: 36px;
        list-style: none;
    }
}






