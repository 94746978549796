.leveragedSec_cnt {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px;
    background-color: rgb(var(--bg-2));
    margin-bottom: 20px;
    cursor: pointer;
}
.leveragedSec_cnt i {
    font-size: 38px;
    margin-bottom: 10px;
}
.technology-t h4 {
    color: #fcb440;
}
.technology-t {
    text-align: center;
    padding-bottom: 65px;
}
.technology-t h5 {
    font-size: 30px;
    padding-bottom: 10px;
}
.technology-t p {
    font-size: 18px;
}

.timeline .timeline-item .timeline-bar .timeline-dot {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(var(--primary-1));
    color: rgb(var(--n700));
    line-height: 0px;
    margin-bottom: 20px;
}
.timeline .timeline-border {
    width: 100%;
    height: 2px;
    border-top: 1px dashed rgba(var(--primary-1), 0.5);
}
h6.display-six {
    font-size: 30px;
    margin-bottom: 50px;
}
.timeline-content {
    width: 100%;
    max-width: 356px;
}
.nav-tabs .nav-link {
    color: #fff;
    font-size: 19px;
    font-weight: 300;
  
}

ul#uncontrolled-tab-example {
    border-bottom: 1px solid #ffffff47;
    text-align: center;
    justify-content: center;
    padding-bottom: 0px;
    /* margin-bottom: 20px; */
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background-color: transparent;
    border-bottom: 1px solid #ffb430 !important;
    color: #ffb430;
    border: none;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    /* isolation: isolate; */
    border-color:none !important;
}
.leveragedSec_cnt h5 {
    font-size: 17px !important;
}

@media(max-width:991px){
    .timeline-content {
        width: 100%;
        max-width: 100%;
    }
}
@media(max-width:767px){
    ul#uncontrolled-tab-example {

        text-align: left;
        justify-content: left;
       
    }
}
@media(max-width:575px){
    h6.display-six {
        font-size: 26px;
        
    }
    .technology-t {
        padding-top: 30px;
        padding-bottom: 25px;
    }

    .pb-120{
        padding-bottom: 0px !important;
    }
  
    .timeline-dot.fs-four{
        width: 48px;
        min-width: 48px;
        max-width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(var(--primary-1));
        color: rgb(var(--n700));
        line-height: 0px;
        margin-bottom: 20px;
    }
}
@media(max-width:425px){
    .leveragedSec_cnt h5 {
        font-size: 16px !important;
    }
    .leveragedSec_cnt {
        padding: 18px;
       margin-bottom: 10px;
    }
    h6.display-six {
        font-size: 22px;
        margin-bottom: 20px;
        line-height: 34px;
    }
    .technology-t h5 {
        font-size: 24px;
        padding-bottom: 0px;
    }
    .technology-t p {
        font-size: 17px;
    }
}

@media(max-width:375px){
    .leveragedSec_cnt {
       
        padding: 16px;
        
    }
    .leveragedSec_cnt h5 {
        font-size: 14px !important;
    }
    .nav-tabs .nav-link {
        font-size: 17px;
    }
}