@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700;800;900&amp;display=swap");
:root {
  --head-font: "Kanit", sans-serif;
  --primary-1: 255, 179, 64;
  --primary-2: 122, 110, 255;
  --primary-3: 255, 77, 66;
  --secondary-1: 13, 226, 124;
  --n10: 250, 250, 251;
  --n20: 245, 246, 247;
  --n30: 235, 236, 239;
  --n40: 223, 224, 228;
  --n50: 193, 196, 204;
  --n60: 178, 182, 191;
  --n70: 166, 170, 181;
  --n80: 151, 156, 168;
  --n90: 136, 142, 156;
  --n100: 121, 128, 144;
  --n200: 106, 114, 131;
  --n300: 91, 100, 119;
  --n400: 79, 88, 109;
  --n500: 64, 74, 96;
  --n600: 52, 62, 86;
  --n700: 34, 46, 72;
  --n800: 19, 32, 59;
  --n900: 7, 20, 49;
  --n0: 255, 255, 255;
  /* background colors */
  --bg-1: 28, 29, 32;
  --bg-2: 38, 38, 41;
  --bg-3: 59, 59, 60;
  --transition: all 0.5s ease-in-out;
  --shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.6);
  --gradient: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 45.21%,
    rgba(0, 0, 0, 0.44) 67.45%,
    #000 100%
  );
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--head-font);
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  color: rgb(var(--n0));
  background: rgb(var(--bg-1));
  min-height: 100vh;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(var(--primary-1));
  border-radius: 5px;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-thumb {
  background: rgb(var(--secondary-1));
  border-radius: 5px;
}

::-moz-selection {
  color: rgb(var(--primary-1));
  background: rgb(var(--n0));
}

::selection {
  color: rgb(var(--primary-1));
  background: rgb(var(--n0));
}
.loader-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
}
.w-100 {
  width: 100% !important;
}
.pt-120 {
  padding-top: 80px;
}
.pb-120 {
  padding-bottom: 80px;
}
.fw-normal {
  font-weight: 300 !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}
.bl-1 {
  border-left: 2px solid rgb(var(--secondary-1));
}
.fs-four {
  font-size: 24px;
  line-height: 130%;
  margin: 0;
}
.fw-medium {
  font-weight: 400 !important;
}
.tcp-1 {
  color: #fcb440 !important;
}
.tcn-900 {
  color: rgb(var(--n900));
}
.tcp-4 {
  color: rgb(var(--secondary-1));
}
.bcp-4 {
  background: rgb(var(--secondary-1));
}
.bgc-2 {
  background-color: rgb(var(--bg-2));
}
.bgc-3 {
  background-color: rgb(var(--bg-3));
}
.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.bttn-1,
.bttn-2 {
  display: inline-flex;
  padding: 12px 12px 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  transition: var(--transition);
  background: rgb(var(--primary-1));
  color: rgb(var(--n900));
  font-weight: 600;
  position: relative;
  text-decoration: none;
  border: none;
}
.bttn-1:hover,
.bttn-2:hover {
  color: #000;
  border-color: rgb(var(--n0));
  background: rgb(50 207 122);
}
.bttn-1 .icon,
.bttn-2 .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: rgb(var(--primary-1));
  background: rgb(var(--bg-1));
  transition: var(--transition);
  border-radius: 50%;
  font-size: 12px;
  box-sizing: border-box;
}
.icon-btn .icon {
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--primary-1));
  color: rgb(var(--n900));
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: var(--transition);
  font-size: 19px;
}

.icon-btn .icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: var(--transition);
  background: rgb(50 207 122);
  border-radius: inherit;
  color: #fff;
  width: 0;
  height: 0;
  z-index: -1;
}

.icon-btn .icon:hover::before {
  width: 100%;
  height: 100%;
  color: #fff;
}

.tag-btn,
.tag-btn-2 {
  display: inline-flex;
  padding: 12px 24px;
  border-radius: 32px;
  background: rgb(var(--bg-3));
  color: rgb(var(--primary-1));
  font-weight: normal;
}
.d-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display-four {
  font-size: 56px;
  line-height: 120%;
  color: #fff;
  font-weight: 600!important;
}
p {
  font-size: 17px;
  line-height: 150%;
  font-weight: 300;
  margin: 0;
}
/* .texture-bg {
  background: url("../src/assets/images/texture-bg.png"), rgb(28, 29, 32);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} */

p.errormessage {
  color: #ff2d2d;
}

.react-tel-input .form-control {
  border-radius: 35px !important;
  height: 45px !important;
  width: 100% !important;
  outline: none !important;
  background: rgb(var(--bg-3)) !important;
  border: none !important;
}

.react-tel-input .form-control.invalid-number {
  border: none !important;
  background-color: #3b3b3c !important;
  border-left-color: #cacaca;
}
.react-tel-input .flag-dropdown.invalid-number {
  border-color: none !important;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-right: 1px solid #a39d9d4a !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
.react-tel-input .selected-flag {
  width: 43px !important;
  padding: 0 0 0 12px !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
p.mobile-error {
  color: red;
}
.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #e9dfdf !important;
}
.requ-mobile-view{
  display: none;
}
/* //////////////////////////////////// same page css ////////////////////////////////////// */
.privacy-section.pt-120 h5 {
  font-size: 27px;
  margin: 20px 0px;
}
.privacy-section.pt-120 p {
  font-size: 18px;
  font-weight: 200 !important;
  color: #ebe9e9;
}
.privacy-section.pt-120 ul {
  padding-left: 15px;
}
.privacy-section.pt-120 ul li {
  list-style: auto;
  font-size: 17px;
  font-weight: 100;
  line-height: 33px;
  padding-left: 18px;
}
.accordion-button {
  font-size: 22px;
  font-weight: 500;
}
.faqs {
  width: 88%;
  margin: 0 auto;
  display: block;
  justify-content: center;
}
.accordion-button:not(.collapsed) {
  background-color: #4cefa3;
  border: none;
  box-shadow: none;
}
.accordion-item {
  color: #fff;
  background-color: transparent;
  border: 1px solid #ffffff29;
  margin-bottom: 10px;
}
.accordion-button {
  color: #fff;
  background-color: transparent;
}
.accordion-body {
  font-size: 16px;
  font-weight: 200;
}

@media (min-width: 992px) {
  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
}

@media (min-width: 992px) {
  .align-items-lg-center {
    align-items: center !important;
  }
}
@media (min-width: 992px) {
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
}
@media (max-width: 767px) {
  .display-four {
    font-size: 42px;
  }

  .pt-120 {
    padding-top: 80px;
  }
  .pb-120 {
    padding-bottom: 80px;
  }
}



@media (max-width: 575px) {
  .requ-mobile-view{
    display: block;
  }
.desktop-req-view{
  display: none !important;
}
  .display-four {
    font-size: 30px;
  }
  .accordion-button {
    font-size: 19px;
    font-weight: 500;
  }
  .fs-four {
    font-size: 20px;
    line-height: 100%;
    margin: 0;
  }
}
@media (max-width: 425px) {
  .display-four {
    font-size: 25px;
  }
  .privacy-section.pt-120 h5 {
    font-size: 22px;
    margin: 10px 0px;
    font-weight: 400;
  }
  .privacy-section.pt-120 p {
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .fs-four {
    font-size: 22px;
    line-height: 100%;
    margin: 0;
  }
  .icon-btn-2 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
  }
  .bttn-1,
  .bttn-2 {
    padding: 9px 12px 9px 15px;
  }
}

@media (max-width: 320px) {
  .display-four {
    font-size: 22px;
  }
}
