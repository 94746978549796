.career-mn h5 {
    font-size: 26px;
    font-weight: 300;
    padding: 12px 0px;
}
.career-mnn h3 {
    font-size: 33px;
}
.career-mnn {
    padding: 41px 50px;
}
.modal-content{
    background-color: #000 !important;
}
.btn-close{
    background-color: #fff !important;
}
.career-close {
    float: right;
    position: absolute;
    right: 19px;
    color: #000;
    cursor: pointer;
}
.career-item {
    background: rgb(var(--bg-2));
    border-radius: 8px;
    box-shadow: 0 0 29px 0 rgba(68,88,144,.12);
    color: #fff;
    margin: 20px 0 0;
    height: 100%;
    padding: 30px 20px;
}
.course-meta-details {
    border-top: 1px solid #ffffff2e;
    height: 84px;
    padding-top: 9px!important;
}
.course-meta-details {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    text-align: center;
}
.course-meta-details li {
    padding-left: 10px;
    padding-right: 10px;
}
.career-title-box {
    height: 143px;
}
.job-portal {
    margin-bottom: 35px;
}
.course-meta-details li span {
    color: #FFB430;
    margin-bottom: 10px;
}
label.form_contact_field {
    margin-bottom: 10px;
}
.carrer-btn {
    background: #FFB430;
    color: #000;
    padding: 4px 20px;
    border-radius: 30px;
    display: flex;
    width: 100px;
    text-align: center;
    justify-content: center;
    line-height: 25px;
    margin: 0 auto;
    border: none;
    cursor: pointer;
}
.carrer-btn-1 {
    background: #FFB430;
    color: #000;
    padding: 7px 20px;
    border-radius: 30px;
    display: flex;
    width: 150px;
    text-align: center;
    justify-content: center;
    line-height: 25px;
    margin: 0 auto;
    border: none;
}
.btn-primary:hover{
    background: #13e986;
    color: #000;
}
span.badge.bg-secondary {
    background: #4e4d4c !important;
    padding: 12px;
    margin: 2px;
    color: #fff;
}
li.caree-usr {
    border-right: 1px solid #ffffff2e;
}
ul.course-meta-details.list-inline.w-100 p {
    padding-top: 10px;
    font-size: 16px;
}
.career-benifit p {
    font-size: 18px;
    /* line-height: 32px; */
}
section#career-contact {
    padding: 25px 30px;
    background: rgb(var(--bg-2));
}
@media(max-width:991px){
    .career-mnn {
        padding: 0px 5px;
    }
    .career-item {
        height: auto;
    }
    .career-benifit p {
        margin-bottom: 30px;
    }
}

@media(max-width:767px){
    .career-mn {
        padding: 50px 0px;
    }
    .meet-carrer {
        display: flex;
        flex-direction: column-reverse;
    }
    .career-mnn {
        padding: 0px 5px 20px 0px;
    }
}

@media(max-width:575px){
    .career-mnn h3 {
        font-size: 28px;
    }
}

@media(max-width:425px){
    .career-mn h5 {
        font-size: 22px;
        padding: 4px 0px;
    }
    .career-mnn h3 {
        font-size: 25px;
    }
    .career-benifit p {
        font-size: 17px;
    }
    .career-title-box {
        height: auto;
    }
    .job-portal {
        margin-bottom: 20px;
        margin-top: 15px;
    }
    .career-mn {
        padding: 14px 0px 35px 0px;
    }
}

@media(max-width:375px){
    .meet-carrer {
        margin-top: 25px !important;
    }
    .career-mnn h3 {
        font-size: 24px;
    }
    section#career-contact {
        padding: 25px 10px;
    }
}